import { createRouter, createWebHistory } from "vue-router";

const routes = [
  // 登录
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/login"),
  },

  // 重置密码
  {
    path: "/forgotPassword",
    name: "ForgotPassword",
    component: () => import("@/views/forgotPassword/index.vue"),
  },

  // 选择/注册企业
  {
    path: "/chooseProduct",
    name: "ChooseProduct",
    component: () => import("@/views/chooseProduct/index.vue"),
  },
  // 注册企业
  {
    path: "/increaseProduct",
    name: "IncreaseProduct",
    component: () => import("@/views/increaseProduct/index.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
