export default {
  name: "Footer-index",

  setup() {
    function fn() {
      window.location.href = "https://beian.miit.gov.cn/#/Integrated/index";
    }

    return {
      fn
    };
  }

};