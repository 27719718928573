import request from "./request";

// 登录
export const login = (data) => {
  return request({
    url: "/core/admin/user/login",
    method: "POST",
    data,
  });
};
// 获取企业信息, 通过corp_id,或者自定义域名
export const getCorpInfo = (data) => {
  return request({
    url: "/core/common/public/getCorpInfo",
    method: "POST",
    data,
  });
};
// 获取验证码
export const obtainCode = (data) => {
  return request({
    url: "/core/common/public/sysSendCaptcha",
    method: "POST",
    data,
  });
};
//
// 登出
export const logout = () => {
  return request({
    url: "/core/admin/user/logout",
    method: "GET",
  });
};
// 刷新token
export const refreshToken = (params) => {
  return request({
    url: "/core/admin/user/refreshDefaultToken",
    method: "GET",
    params,
  });
};

// 重置密码
export const resetPassword = (data) => {
  return request({
    url: "/core/admin/user/modifyPasswordByCode",
    method: "POST",
    data,
  });
};
// 注册企业
export const registerCorp = (data) => {
  return request({
    url: "/core/admin/user/registerCorp",
    method: "POST",
    data,
  });
};

// 选择企业
export const selectCorpByMulti = (data) => {
  return request({
    url: "/core/admin/user/selectCorpByMulti",
    method: "POST",
    data,
  });
};
