import { ElMessage } from "element-plus";
/**
 * 通用js方法封装处理
 */

/**
 * 表格时间格式化
 */
export function parseTime(cellValue) {
  if (cellValue == null || cellValue == "") return "";
  let date = new Date(cellValue * 1000); // 时间戳为秒：10位数
  //let date = new Date(value)    // 时间戳为毫秒：13位数
  let year = date.getFullYear();
  let month =
    date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
  let day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
  let hour = date.getHours() < 10 ? `0${date.getHours()}` : date.getHours();
  let minute =
    date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes();
  let second =
    date.getSeconds() < 10 ? `0${date.getSeconds()}` : date.getSeconds();
  return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
}

/**
 * 日期转时间戳
 */
export function parseStamp(stamp) {
  if (stamp == null || stamp == "") return "";
  let date = new Date(stamp);
  let time = date.getTime(); // 毫秒 13位
  // let time = Date.parse(date); // 秒 10位
  return time / 1000;
}

/**
 * 判断数据类型
 */
export function getDataType(data) {
  if (typeof data == "object") {
    return Object.prototype.toString.call(data).slice(8, -1);
  } else {
    return typeof data;
  }
}

// 表单重置
export function resetForm(refName) {
  if (this.$refs[refName]) {
    this.$refs[refName].resetFields();
  }
}

/**
 * 构造树型结构数据
 * @param {*} data 数据源
 * @param {*} id id字段 默认 'id'
 * @param {*} parent_id 父节点字段 默认 'parent_id'
 * @param {*} children 孩子节点字段 默认 'children'
 */
export function handleTree(data, id, parent_id, children) {
  let config = {
    id: id || "id",
    parent_id: parent_id || "parent_id",
    childrenList: children || "children",
  };

  var childrenListMap = {};
  var nodeIds = {};
  var tree = [];

  for (let d of data) {
    let parent_id = d[config.parent_id];
    if (childrenListMap[parent_id] == null) {
      childrenListMap[parent_id] = [];
    }
    nodeIds[d[config.id]] = d;
    childrenListMap[parent_id].push(d);
  }

  for (let d of data) {
    let parent_id = d[config.parent_id];
    if (nodeIds[parent_id] == null) {
      tree.push(d);
    }
  }

  for (let t of tree) {
    adaptToChildrenList(t);
  }

  function adaptToChildrenList(o) {
    if (childrenListMap[o[config.id]] !== null) {
      o[config.childrenList] = childrenListMap[o[config.id]];
    }
    if (o[config.childrenList]) {
      for (let c of o[config.childrenList]) {
        adaptToChildrenList(c);
      }
    }
  }
  return tree;
}

// 复制文本方法
export function copyFunction(context) {
  // 创建输入框元素
  let oInput = document.createElement("input");
  // 将想要复制的值
  oInput.value = context;
  // 页面底部追加输入框
  document.body.appendChild(oInput);
  // 选中输入框
  oInput.select();
  // 执行浏览器复制命令
  document.execCommand("Copy");
  // 弹出复制成功信息
  ElMessage.success("复制成功");
  // 复制后移除输入框
  oInput.remove();
}

/**
 * @description 格式化金额
 * @param number：要格式化的数字
 * @param decimals：保留几位小数 默认0位
 * @param decPoint：小数点符号 默认.
 * @param thousandsSep：千分位符号 默认为,
 */
export const formatMoney = (
  number,
  decimals = 0,
  decPoint = ".",
  thousandsSep = ","
) => {
  number = (number + "").replace(/[^0-9+-Ee.]/g, "");
  let n = !isFinite(+number) ? 0 : +number;
  let prec = !isFinite(+decimals) ? 0 : Math.abs(decimals);
  let sep = typeof thousandsSep === "undefined" ? "," : thousandsSep;
  let dec = typeof decPoint === "undefined" ? "." : decPoint;
  let s = "";
  let toFixedFix = function (n, prec) {
    let k = Math.pow(10, prec);
    return "" + Math.ceil(n * k) / k;
  };
  s = (prec ? toFixedFix(n, prec) : "" + Math.round(n)).split(".");
  let re = /(-?\d+)(\d{3})/;
  while (re.test(s[0])) {
    s[0] = s[0].replace(re, "$1" + sep + "$2");
  }
  if ((s[1] || "").length < prec) {
    s[1] = s[1] || "";
    s[1] += new Array(prec - s[1].length + 1).join("0");
  }
  return s.join(dec);
};

// 生成随机数
export const randomNumber = () => {
  let num = Math.floor(Math.random() * 100000000000000000);
  return num;
};

// 中国标准时间转日期格式
export const timeFormat = (time) => {
  let year = time.getFullYear();
  let month = time.getMonth() + 1;
  let day = time.getDate();
  return year + "-" + month + "-" + day;
};

/**
 * num 天数
 * type （front 几天前） （back 几天后）
 * 返回格式 2020-10-10
 */
export const recentDate = (num = 0, type = "front") => {
  let date1 = new Date();
  let date2 = new Date(date1);

  //-num为num天前，+num可以获得num天后的日期
  type === "front"
    ? date2.setDate(date1.getDate() - num)
    : date2.setDate(date1.getDate() + num);

  //num天前（月份判断是否小于10，小于10的前面+0）
  let agoDay = `${date2.getFullYear()}-${
    date2.getMonth() + 1 < 10
      ? `0${date2.getMonth() + 1}`
      : date2.getMonth() + 1
  }-${date2.getDate()}`;

  // return `${agoDay} 00:00:00`;
  return agoDay;
};

// 日期格式 年-月-日 星期
export const homeToday = () => {
  let date = new Date();
  //我们写一个  2021年 12月 17日 星期5；
  // let year = date.getFullYear();
  let month = date.getMonth() + 1;
  let dates = date.getDate();
  let arr = [
    "星期日",
    "星期一",
    "星期二",
    "星期三",
    "星期四",
    "星期五",
    "星期六",
  ];
  let day = date.getDay();
  // return "今天是" + year + "年" + month + "月" + dates + "日，" + arr[day];
  return "今天是" + month + "月" + dates + "日，" + arr[day];
};

// 24小时全天亲密亲密亲密问候
export const judgeDate = (name) => {
  let now = new Date().getHours();
  let text = "";
  if (now < 6) {
    text = "凌晨好！";
  } else if (now < 9) {
    text = "早上好！";
  } else if (now < 12) {
    text = "上午好！";
  } else if (now < 14) {
    text = "中午好！";
  } else if (now < 17) {
    text = "下午好！";
  } else if (now < 19) {
    text = "傍晚好！";
  } else if (now < 22) {
    text = "晚上好！";
  } else {
    text = "早上好！";
  }
  return name ? `${name}，${text}` : text;
};
