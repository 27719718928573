// 组件全局插件
import Footer from "@/components/Footer";
import Header from "@/components/Header";
import headers from "@/components/headers";
import footers from "@/components/footers";
import Partner from "@/components/Partner";
import ModuleName from "@/components/ModuleName";
import ExhibitionMode from "@/components/ExhibitionMode";
import advantage from "@/components/advantage";
const componentPlugin = {
  install(Vue) {
    Vue.component("HeaderPlugin", Header);
    Vue.component("FooterPlugin", Footer);
    Vue.component("headersPlugin", headers);
    Vue.component("footersPlugin", footers);
    Vue.component("PartnerPlugin", Partner);
    Vue.component("ModuleName", ModuleName);
    Vue.component("ExhibitionMode", ExhibitionMode);
    Vue.component("advantagePlugin", advantage);
  },
};

export default componentPlugin;
