import router from "./index";
import store from "@/store";

const whiteList = [
  "/login",
  "/chooseProduct",
  "/forgotPassword",
  "/increaseProduct",
];
router.beforeEach((to, from, next) => {
  if (store.getters.token) {
    if (to.path === "/login") {
      next();
    } else {
      next();
    }
  } else {
    if (whiteList.includes(to.path)) {
      next();
    } else {
      next("/login");
    }
  }
});
