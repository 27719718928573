import { unref as _unref, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-22f96749"), n = n(), _popScopeId(), n);

const _hoisted_1 = ["xlink:href"];
import { computed } from "vue";
export default {
  __name: 'index',
  props: {
    icon: {
      type: String,
      required: true
    },
    width: {
      type: String
    },
    height: {
      type: String
    }
  },

  setup(__props) {
    const props = __props;
    const iconName = computed(() => {
      return `#icon-${props.icon}`;
    });
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("svg", {
        class: "svg-icon",
        "aria-hidden": "true",
        style: _normalizeStyle({
          width: __props.width,
          height: __props.height
        })
      }, [_createElementVNode("use", {
        "xlink:href": _unref(iconName)
      }, null, 8, _hoisted_1)], 4);
    };
  }

};