import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import { useRouter } from "vue-router";
export default {
  __name: 'App',

  setup(__props) {
    const router = useRouter();

    if (location.pathname === "/") {
      router.push("/login");
    }

    return (_ctx, _cache) => {
      const _component_router_view = _resolveComponent("router-view");

      return _openBlock(), _createBlock(_component_router_view);
    };
  }

};