import axios from "axios";
import { ElMessage } from "element-plus";
//import { diffTokenTime } from "@/utils/auth";
import store from "@/store";
import router from "@/router";
import { throttle } from "lodash";
import { refreshToken } from "@/api/login";

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 5000,
});

service.interceptors.request.use(
  (config) => {
    const token = sessionStorage.getItem("token");
    if (token) {
      // if (diffTokenTime()) {
      //   store.dispatch("app/logout");
      //   // token 失效
      //   return Promise.reject("登录状态已过期，请重新登录！");
      // }
      //config.headers.Authorization = "Bearer " + sessionStorage.getItem("token");
      config.headers.Authorization = "Bearer " + `${token}`;
    }
    return config;
  },
  (error) => {
    //return Promise.reject(new Error(error));
    return Promise.reject(error);
  }
);

service.interceptors.response.use(
  async (response) => {
    const { data, errno, errmsg } = response.data;
    if (errno === 200) {
      return data;
    } else if (errno === 441) {
      const oldToken = sessionStorage.getItem("token");
      throttle(
        refreshToken({ token: oldToken })
          .then((res) => {
            const token = res.token;
            store.commit("app/setToken", token);
            //response.headers.Authorization = `${token}`;
            response.headers.Authorization = "Bearer " + `${token}`;
          })
          .catch(() => {
            ElMessage.error("请重新登录");
            store.dispatch("app/logout");
            router.push(`/login`);
          }),
        10000
      );
    } else {
      ElMessage.error(errmsg);
    }
    return Promise.reject(errmsg);
  },
  (error) => {
    //error && ElMessage.error(error);
    return Promise.reject(error);
  }
);

export default service;
