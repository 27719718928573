export default {
  namespaced: true,
  state: () => ({
    role: JSON.parse(sessionStorage.getItem("role")) || "{}",
    staff: JSON.parse(sessionStorage.getItem("staff")) || "{}",
  }),
  mutations: {
    setRole(state, role) {
      console.log(role);
      state.role = role;
      sessionStorage.setItem("role", JSON.stringify(role));
    },
    setStaff(state, staff) {
      console.log(staff);
      state.staff = staff;
      sessionStorage.setItem("staff", JSON.stringify(staff));
    },
  },
  actions: {},
};
