import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "@/styles/index.scss";
import SvgIcon from "@/icons";
import "@/router/permission";
import * as ELIcons from "@element-plus/icons-vue";
import {
  parseTime,
  resetForm,
  handleTree,
  parseStamp,
  getDataType,
  copyFunction,
  formatMoney,
  randomNumber,
} from "@/utils/common";
import "element-plus/dist/index.css";

import componentPlugin from "@/components"; //全局注册组件

const app = createApp(App);
// 全局方法挂载
app.config.globalProperties.resetForm = resetForm;
app.config.globalProperties.parseTime = parseTime; // 时间戳转日期
app.config.globalProperties.handleTree = handleTree; //树型结构
app.config.globalProperties.parseStamp = parseStamp; // 日期转时间戳
app.config.globalProperties.getDataType = getDataType; // 数据类型
app.config.globalProperties.copyFunction = copyFunction; // 复制文本方法
app.config.globalProperties.formatMoney = formatMoney; // 数据类型
app.config.globalProperties.randomNumber = randomNumber; // 数据类型

// 全局组件挂载
for (const iconName in ELIcons) {
  app.component(iconName, ELIcons[iconName]);
}
SvgIcon(app);
app.use(store).use(router).use(componentPlugin).mount("#app");
