//判断是手机端用户端
export const getUserBrowser = () => {
  const ua = navigator.userAgent.toLowerCase();
  if (
    /ipad|iphone|midp|rv:1.2.3.4|ucweb|android|windows ce|windows mobile/.test(
      ua
    )
  ) {
    return 0;
  } else {
    return 1;
  }
};
export const getUrlParam = (name) => {
  let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
  let r = window.location.search.substr(1).match(reg);
  if (r != null) {
    return decodeURIComponent(r[2]);
  }
  return null;
};
export const isOfficialDomain = () => {
  let domain = window.location.hostname;
  let officialDomain = process.env.VUE_APP_OFFICIAL_DOMAIN;
  if (officialDomain.indexOf(domain) != -1) {
    return true;
  }
  return false;
};
export const isEmpty = (str) => {
  let type = typeof str;
  let result = false;
  switch (type) {
    case "undefined":
      result = true;
      break;
    case "string":
      if (str == "") {
        result = true;
      }
      break;
    case "object":
      if (null == str || Object.keys(str).length === 0) {
        result = true;
      }
      break;
    case "number":
      if (str == 0) {
        result = true;
      }
      break;
    default:
      break;
  }
  return result;
};
export default {
  getUserBrowser,
  getUrlParam,
};
