// @ts-nocheck
import {
  login as loginApi,
  registerCorp,
  selectCorpByMulti,
  // getCorpInfo,
} from "@/api/login";
import { isOfficialDomain } from "@/utils/index";
import router from "@/router";
import store from "@/store";
import { ElMessage } from "element-plus";
export default {
  namespaced: true,
  state: () => ({
    token: localStorage.getItem("token") || "",
    siderType: true,
    products: sessionStorage.getItem("products") || "[]",
    auth: sessionStorage.getItem("auth") || "{}",
  }),
  mutations: {
    setToken(state, token) {
      state.token = token;
      localStorage.setItem("token", token);
    },
    changeSiderType(state) {
      state.siderType = !state.siderType;
    },
    setAuth(state, auth) {
      state.auth = JSON.stringify(auth);
      sessionStorage.setItem("auth", JSON.stringify(auth));
    },
    setProducts(state, products) {
      state.products = JSON.stringify(products);
      sessionStorage.setItem("products", JSON.stringify(products));
    },
  },
  actions: {
    // 登录
    login({ commit }, userInfo) {
      return new Promise((resolve, reject) => {
        loginApi(userInfo)
          .then((res) => {
            if (isOfficialDomain()) {
              if (res.next === "single") {
                commit("setToken", res.token);
                window.open(
                  process.env.VUE_APP_SASS + "?token=" + res.token,
                  "_self"
                );
              } else if (res.next === "multi") {
                // 有多个企业
                commit("setAuth", res.l_s);
                commit("setProducts", res.corp_list);
                router.push({
                  name: "ChooseProduct",
                  params: {
                    productType: true,
                  },
                });
              } else if (res.next === "none") {
                // 没有企业
                commit("setAuth", res.l_s);
                commit("setProducts", []);
                router.push("/increaseProduct");
              }
              resolve();
            } else {
              if (res.next === "single") {
                commit("setToken", res.token);
                window.open(
                  process.env.VUE_APP_SASS + "?token=" + res.token,
                  "_self"
                );
              } else {
                ElMessage.error("接口数据错误");
              }
              resolve();
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    // 注册企业
    registerCorp({ commit }, saasInfo) {
      return new Promise((resolve, reject) => {
        registerCorp(saasInfo)
          .then(async (res) => {
            commit("setToken", res.token);
            const token = store.state.app.token;
            window.open(process.env.VUE_APP_SASS + "?token=" + token, "_self");
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    // 选择企业
    selectCorpByMulti({ commit }, saasInfo) {
      return new Promise((resolve, reject) => {
        selectCorpByMulti(saasInfo)
          .then(async (res) => {
            commit("setToken", res.token);
            const token = store.state.app.token;
            window.open(process.env.VUE_APP_SASS + "?token=" + token, "_self");
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    // 退出
    logout({ commit }) {
      commit("setToken", "");
      localStorage.clear();
      sessionStorage.clear();
      router.replace("/login");
    },
  },
};
